export function objIsEmpty(obj) {
  for (var i in obj) return false;
  return true;
}

export function inArrayObj(arrObj, objToCompare) {
  var matched = false;
  if (arrObj && objToCompare) {
    arrObj.map((obj) => {
      if (
        !Object.keys(obj)
          .map((key) => {
            if (key) {
              return obj[key] === objToCompare[key];
            }
            return false;
          })
          .includes(false)
      ) {
        matched = true;
      }
      return false;
    });
  }
  return matched;
}

export function getCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function setCookie(name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

export function deleteCookie(name) {
  setCookie(name, "", -1);
}

export function isDarkMod() {
  if (typeof window !== "undefined" && localStorage) {
    return localStorage.getItem("dark-mode")
      ? JSON.parse(localStorage.getItem("dark-mode"))
      : false;
  } else {
    return null;
  }
}

export function nl2br(str, replaceMode, isXhtml) {
  var breakTag = isXhtml ? "<br />" : "<br>";
  var replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
  return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

export function format_price(price) {
  let euroEU = Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  return euroEU.format(price);
}
