import React from "react";
import { Link } from "gatsby";

const Breadcrumb = ({ location }) => {
  const slugify = require("slugify");
  return (
    <div className="print:hidden mt-4 ml-4">
      <Link
        className="btn-sm bg-white text-base text-gray-600"
        to={`/${slugify(location, {
          lower: true,
          strict: true,
        })}`}
      >
        <span className="material-icons-outlined text-base text-gray-600 mr-3">
          arrow_back_ios
        </span>
        Retour à la liste des {location}
      </Link>
    </div>
  );
};
export default Breadcrumb;
