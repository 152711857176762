import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { useFormik } from "formik";
import * as yup from "yup";
import config from "../../data/SiteConfig";
import Layout from "../layout";
import Seo from "../components/Seo/Seo";
import Contact from "../components/Contact/Contact";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { ButtonField, TextField } from "../utils/fields";
import { format_price } from "../utils/javascript-function";

const BonDeCommandePage = ({ data: gqlData }) => {
  const { directus } = gqlData;
  const slugify = require("slugify");

  const [loading, setLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalShipping, setTotalShipping] = useState(0);

  const shippingFromQuantity = (quantity) => {
    if (quantity > 6) {
      return (
        <a
          className="text-red-500 font-bold"
          rel="noreferrer"
          target="_blank"
          href="/contact"
        >
          Nous consulter
        </a>
      );
    } else if (quantity <= 6 && quantity >= 5) {
      return 13.8;
    } else if (quantity <= 4 && quantity >= 2) {
      return 7.8;
    } else if (quantity > 0) {
      return 5.9;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (books.length === 0) {
      const initBooks = () => {
        return directus.publications.reduce((acc, curr) => {
          acc.push({ ...curr, quantity: 0, total_price: 0 });
          return acc;
        }, []);
      };
      setBooks(initBooks());
    } else {
      // Set total price each time books change
      const reducedTotalPrice = books.reduce((acc, curr) => {
        return acc + curr.total_price;
      }, 0);
      const reducedTotalQuantity = books.reduce((acc, curr) => {
        return acc + curr.quantity;
      }, 0);

      setTotalPrice(reducedTotalPrice);
      setTotalQuantity(reducedTotalQuantity);
      setTotalShipping(shippingFromQuantity(reducedTotalQuantity));
    }
  }, [directus.publications, books]);

  const quantityChange = (id) => {
    return (e) => {
      setBooks(
        books.reduce((acc, curr) => {
          if (curr.id === id) {
            acc.push({
              ...curr,
              quantity: parseInt(e.target.value),
              total_price: parseFloat(e.target.value * curr.price),
            });
          } else {
            acc.push(curr);
          }
          return acc;
        }, [])
      );
    };
  };

  const validationSchema = yup.object({
    name: yup.string("Entrez votre nom et prénom").required("Champ requis"),
    address: yup.string("Votre adresse").required("Champ requis"),
    postal_code: yup.string("Votre code postal").required("Champ requis"),
    location: yup.string("Votre ville et pays").required("Champ requis"),
    phone: yup.string("Entrez votre numéro"),
    email: yup
      .string("Entrez une adresse email")
      .email("Entrez une adresse email valide")
      .required("Champ requis"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      postal_code: "",
      location: "",
      phone: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values && totalQuantity > 0) {
        // Success
        setLoading(true);
        // Set print action
        if (window !== "undefined") {
          window.print();
        }

        // actions.validateForm()
        setLoading(false);
      } else {
        // Sélectionner au moins un livre
      }
    },
  });

  return (
    <Layout>
      <div className="print:hidden h-20 md:h-28"></div>
      <div className="bon_de_commande-container">
        <Seo
          title={`Bon de commande`}
          description={config.siteDescription4}
          article={false}
        />
        <Breadcrumb location={"publications"} />

        <div className="bon-de-commande pt-8 pb-4 flex items-center">
          <div className="relative w-full">
            <div className="print:max-w-full max-w-6xl mx-auto">
              <h1 className="print:hidden text-center mt-6 mb-8">
                Bon de commande
              </h1>
              <hr className="print:hidden border-none h-2 bg-red-700" />
              <div className="print:hidden text-center max-w-3xl mx-auto mt-6 mb-12">
                <p className="text-lg text-gray-600">
                  Pour passer votre commande, remplissez ce formulaire,
                  imprimez-le et joignez-le à votre chèque.
                </p>
              </div>
              <div className="w-full relative">
                <div className="pb-8 max-w-6xl mx-auto px-3 sm:px-6">
                  <div className="hidden print:block">
                    <div className="grid gap-8 grid-cols-2">
                      <div>
                        <h6 className="mt-0 pt-0">
                          Joignez ce document à votre chèque (libellé à l'ordre
                          du GFEN Secteur Langues) que vous glisserez dans une
                          enveloppe.
                        </h6>
                        <p className="mt-2 border-2 border-gray-700 px-2 py-1 rounded-sm">
                          GFEN Secteur Langues
                          <br />
                          Centre Associatif Boris Vian
                          <br />
                          13 avenue Marcel Paul
                          <br />
                          69200, Vénissieux
                        </p>
                      </div>
                      <div>
                        <h5 className="mt-2">Informations personnelles</h5>
                        <p>
                          <strong>Prénom et nom : </strong>
                          {formik.values.name}
                        </p>
                        <p>
                          <strong>Email : </strong>
                          {formik.values.email}
                        </p>
                        <p>
                          <strong>Téléphone : </strong>
                          {formik.values.phone}
                        </p>
                        <p>
                          <strong>Adresse : </strong>
                          {formik.values.address} {formik.values.postal_code}{" "}
                          {formik.values.location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <form
                    className="print:border-none print:space-y-0 print:divide-y-0 print:mt-8 border-4 border-gray-300 rounded-sm divide-y-4 space-y-8 divide-gray-300"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="print:hidden p-4">
                      <h4>Informations personnelles</h4>
                      <div className="grid grid-cols-1 gap-6">
                        <TextField
                          fullWidth
                          type="text"
                          className="mt-8 bg-gray-100 dark:bg-gray-700"
                          label="Prénom et nom"
                          placeholder="Prénom et nom"
                          id="information-name"
                          name="name"
                          value={formik.values.name ? formik.values.name : ""}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <TextField
                          fullWidth
                          className="mt-8 bg-gray-100 dark:bg-gray-700"
                          label="Adresse email"
                          placeholder="Adresse email"
                          id="information-email"
                          name="email"
                          type="email"
                          value={formik.values.email ? formik.values.email : ""}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                        <TextField
                          fullWidth
                          className="mt-8 bg-gray-100 dark:bg-gray-700"
                          label="Téléphone"
                          placeholder="Téléphone"
                          id="information-phone"
                          name="phone"
                          type="tel"
                          value={formik.values.phone ? formik.values.phone : ""}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                        />
                      </div>
                      <div className="grid grid-cols-1 gap-6">
                        <TextField
                          fullWidth
                          type="text"
                          className="mt-8 bg-gray-100 dark:bg-gray-700"
                          label="Adresse postale"
                          placeholder="Adresse postale"
                          id="information-address"
                          name="address"
                          value={
                            formik.values.address ? formik.values.address : ""
                          }
                          onChange={formik.handleChange}
                          error={
                            formik.touched.address &&
                            Boolean(formik.errors.address)
                          }
                          helperText={
                            formik.touched.address && formik.errors.address
                          }
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <TextField
                          fullWidth
                          type="text"
                          className="mt-8 bg-gray-100 dark:bg-gray-700"
                          label="Code postal"
                          placeholder="Code postal"
                          id="information-postal_code"
                          name="postal_code"
                          value={
                            formik.values.postal_code
                              ? formik.values.postal_code
                              : ""
                          }
                          onChange={formik.handleChange}
                          error={
                            formik.touched.postal_code &&
                            Boolean(formik.errors.postal_code)
                          }
                          helperText={
                            formik.touched.postal_code &&
                            formik.errors.postal_code
                          }
                        />
                        <TextField
                          fullWidth
                          type="text"
                          className="mt-8 bg-gray-100 dark:bg-gray-700"
                          label="Ville et pays"
                          placeholder="Ville et pays"
                          id="information-location"
                          name="location"
                          value={
                            formik.values.location ? formik.values.location : ""
                          }
                          onChange={formik.handleChange}
                          error={
                            formik.touched.location &&
                            Boolean(formik.errors.location)
                          }
                          helperText={
                            formik.touched.location && formik.errors.location
                          }
                        />
                      </div>
                    </div>
                    <div className="print:p-0 p-4">
                      <h4 className="print:hidden">
                        Choisissez un ou plusieurs livres
                      </h4>
                      <p className="print:hidden">
                        Modifiez la quantité de livres souhaités et générez le
                        bon de commande
                      </p>
                      <h4 className="hidden print:block print:mb-2">
                        Bon de commande
                      </h4>
                      <div className="mt-6 print:mt-0 shadow-sm overflow-hidden">
                        <table className="border-collapse table-auto w-full text-sm">
                          <thead>
                            <tr>
                              <th className="border-b dark:border-slate-600 font-medium p-2 pl-8 pt-0 pb-3 text-slate-800 dark:text-slate-200 text-left">
                                Titre du livre
                              </th>
                              <th className="border-b dark:border-slate-600 font-medium p-2 pt-0 pb-3 text-slate-800 dark:text-slate-200 text-left">
                                Prix unitaire
                              </th>
                              <th className="border-b dark:border-slate-600 font-medium p-2 pr-8 pt-0 pb-3 text-slate-800 dark:text-slate-200 text-left">
                                Quantité
                              </th>
                              <th className="border-b dark:border-slate-600 font-medium p-2 pr-8 pt-0 pb-3 text-slate-800 dark:text-slate-200 text-left">
                                Prix total
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-slate-800">
                            {books &&
                              books.map((book, index) => {
                                return (
                                  <tr
                                    className={
                                      book.quantity === 0 ? "print:hidden" : ""
                                    }
                                    key={index + book.id}
                                  >
                                    <td className="border-b border-slate-200 dark:border-slate-600 p-2 pl-8 text-slate-800 dark:text-slate-800">
                                      <a
                                        rel="noreferrer"
                                        target="_blank"
                                        className={`flex items-center text-lg leading-6 font-normal ${
                                          !book.intro ? "py-4" : ""
                                        }`}
                                        href={`/publications/${slugify(
                                          book.title,
                                          {
                                            lower: true,
                                            strict: true,
                                          }
                                        )}`}
                                      >
                                        <span
                                          className={`p-0 m-0 -ml-2 text-left material-icons-outlined`}
                                        >
                                          arrow_right
                                        </span>
                                        {book.title}
                                      </a>
                                      {book.intro && (
                                        <p className="mt-1">
                                          <i className="text-gray-600 text-sm">
                                            {book.intro}
                                          </i>
                                        </p>
                                      )}
                                    </td>
                                    <td className="border-b border-slate-200 dark:border-slate-600 p-2 text-slate-800 dark:text-slate-800">
                                      {format_price(book.price)}
                                    </td>
                                    <td className="border-b border-slate-200 dark:border-slate-600 p-2 pr-8 text-slate-800 dark:text-slate-800">
                                      <input
                                        className="max-w-[100px] text-left pl-4 peer px-3 font-semibold shadow font-body tracking-wide bg-white dark:bg-gray-950 h-10 text-base text-gray-900 dark:text-gray-200 border-b-2 border-gray-300 focus:border-gray-700 dark:focus:border-gray-200 placeholder-transparent focus:outline-none rounded-sm"
                                        type="number"
                                        defaultValue={0}
                                        onChange={quantityChange(book.id)}
                                        min="0"
                                        step="1"
                                        name="item-quantity"
                                      />
                                    </td>
                                    <td className="border-b border-slate-200 dark:border-slate-600 p-2 pr-8 text-slate-800 dark:text-slate-800">
                                      <p>{format_price(book.total_price)}</p>
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr>
                              <td
                                colSpan={2}
                                className="border-b border-slate-200 dark:border-slate-600 p-2 pl-8 text-slate-800 dark:text-slate-800"
                              >
                                <p className="text-sm">
                                  <u>Frais de port</u>
                                  <br />
                                  <b>Tarif France métropolitaine</b> : 1 livre :
                                  5,90 € ; de 2 à 4 livres : 7,80 € ; 5 ou 6
                                  livres : 13,80€ ; au-delà : nous consulter
                                  <br />
                                  <b>Tarif international</b> : nous consulter
                                </p>
                              </td>
                              <td className="border-b border-slate-200 dark:border-slate-600 p-2 pr-8 text-slate-800 dark:text-slate-800">
                                <p className="pl-4 font-bold text-xl">
                                  x{totalQuantity}
                                </p>
                              </td>
                              <td className="border-b border-slate-200 dark:border-slate-600 p-2 pr-8 text-slate-800 dark:text-slate-800">
                                <p>
                                  {typeof totalShipping === "number"
                                    ? format_price(totalShipping)
                                    : totalShipping}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className="border-b border-slate-200 dark:border-slate-600 p-2 pl-8 text-slate-800 dark:text-slate-800"
                              >
                                <p className="text-sm">
                                  <u>TOTAL (en euros)</u>
                                  <br />
                                  Règlement par chèque à l'ordre du GFEN Secteur
                                  Langues
                                </p>
                              </td>
                              <td className="border-b border-slate-200 dark:border-slate-600 p-2 pr-8 text-slate-800 dark:text-slate-800">
                                <p>
                                  {typeof totalShipping === "number"
                                    ? format_price(totalPrice + totalShipping)
                                    : format_price(totalPrice)}
                                  {typeof totalShipping !== "number" ? (
                                    <React.Fragment>
                                      <br />
                                      <small className="font-bold whitespace-nowrap">
                                        + Frais de port à prévoir
                                      </small>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="print:hidden p-4">
                        <ButtonField
                          fullWidth
                          className="mt-10"
                          isLoading={loading}
                          type="submit"
                          color="secondary"
                          variant="contained"
                        >
                          Générer le bon de commande
                        </ButtonField>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="print:hidden bg-gray-300 pt-12 pb-20 flex justify-center">
          <div className="w-full relative max-w-6xl mx-auto px-3 sm:px-6">
            <h4 className="mb-0 flex items-center text-gray-700">
              <span className={`material-icons-outlined  pr-2 py-0`}>
                email
              </span>
              Contact
            </h4>
            <Contact defaultSubject="commande" background={false} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BonDeCommandePage;

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      publications(sort: "-sort", filter: { price: { _gt: 0 } }) {
        id
        title
        intro
        price
      }
    }
  }
`;
